import {types} from 'mobx-state-tree';

const dimensions = types.model({
    id: types.identifier,
    task: types.string
  })

export const BatchDetails = types.model({
    imageFormat: types.maybeNull(types.string),
    presignUrl: types.maybeNull(types.string),
    codeType: types.maybeNull(types.string),
    codeStatus: types.maybeNull(types.string),
    owner: types.optional(
        types.model({
        groupUuid: types.maybeNull(types.string),
        dimensions: types.optional(types.frozen(),{})
     }), {}
    ),
    tags: types.optional(types.array(types.string), []),
    shortUrl: types.optional(
            types.model({
            domain: types.maybeNull(types.string, "")
        }), {}
    )
})

// "details": {
//     "codeType": "url",
//     "codeStatus": "PUBLISHED",
//     "owner": {
//       "groupUuid": "f0bf3a4c-921d-404a-9ff3-fee18f9dc455",
//       "dimensions": {
//         "c851b3db-bb9a-4bb3-a0b7-509ec74daa31": "34c3da93-ae47-4067-a557-dcd3cdac5e71",
//         "5618512f-4711-4d0d-805e-d4d84ea992e8": "c6b68a7b-90f3-4d0a-907e-f290ed6b42cf"
//       }
//     },
//     "tags": [],
//     "shortUrl": {
//       "domain": "http://group1domain122.com"
//     }