import React, {useState, useContext} from "react";
import {
  Button, Card,
  CardBody,
  CardHeader, CardFooter,
  CardTitle,
  Form,
  FormGroup, Input, CustomInput,
  Label,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Row, Col, InputGroup, InputGroupAddon
} from "reactstrap";
import { observer } from "mobx-react-lite";
import { StyledDropzone } from "../../elements/fileUpload/StyledDropzone";
import TagSelect from "../../elements/tags/TagSelect";
import {useIntl, FormattedMessage } from "react-intl";
import GroupSelect from "../../elements/groups/GroupSelect";
import Select from "react-select";
import PublishDomainSelectView from "../../elements/publishDomain/PublishDomainSelectView";
import {StateContext} from "../../../App";
import QrConfigSelect from "../../elements/qrconfig/QrConfigSelect";
import CodeFilterSelect from "../../elements/filters/CodeFilterSelect";
import BatchSelect from "../../elements/batch/BatchSelect";
import QrDownloadView from "../../qr/qrpreview/qrDownload/QrDownloadView";
import {batchEditTypes, batchTypesConstatns, batchEditTag, codeStatus, formConstants} from "../../../utils/constants";
import GenericModel, {ModalState} from "../../elements/modal/GenericModal";
import ScanbuyModal from "../../elements/modal/GenericModal";
/**
 Form Component to Upload Batch
 */
const BatchForm = observer(({ batchFromState }) => {
  const intl = useIntl();
  const stateContext = useContext(StateContext);
  batchFromState.setOnSelectChanges();
  batchFromState?.codeFilterSelectState.setEmails(batchFromState?.codeModels?.filters?.users);
  batchFromState?.codeFilterSelectState.setCodeTypes(batchFromState?.codeModels?.filters?.types);
  batchFromState?.codeFilterSelectState.setStatusTypes(batchFromState?.codeModels?.filters?.status);
  batchFromState?.codeFilterSelectState.setDomains(batchFromState?.codeModels?.filters?.domains);
  batchFromState?.codeFilterSelectState.setBehaviorTypes(batchFromState?.codeModels?.filters?.behaviors);
  const { title, handleSubmit, uploadFiles, handleCancel, downloadType, batchType } = batchFromState;
  const availableSelectedTagOptions = batchFromState.availableNewSelectedTags.map(tag => ({
    value: tag.uuid,
    label: tag.name,
    // isDisabled: batchFromState.selectedTags.includes(tag.uuid) // we let user select everything
  }));
  const availableNewSelectedTagIds = batchFromState.availableNewSelectedTags;
  const updatedSelectedTagsOptions = availableSelectedTagOptions.filter(option => availableNewSelectedTagIds.includes(option.value));
  console.log("batchFromState updatedSelectedTags: ", batchFromState.updatedSelectedTags);
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Batch Name:</Label>
            <Input type="text"
                   name="batchName"
                   onChange={batchFromState.handleBatchNameChange}
                   value={batchFromState.batchName}
                   disabled={!batchFromState.isBatchEditable}
                   required/>
          </FormGroup>
          <FormGroup>
            <Label>
              Batch Type:
            </Label>
            {/* <Input
              type="select"
              id="batchType"
              name="batchType"
              onChange={handleBatchTypeChange}
            >
              <option>Create</option>
              <option>Download</option>
            </Input> */}
            <Select
              id="batchType"
              name="batchType"
              onChange={(data)=>batchFromState.setBatchType(data.value)}
              value={{ value: batchFromState.batchType, label: batchFromState.batchType }}
              options={[
                { value: batchTypesConstatns.CREATE, label: batchTypesConstatns.CREATE },
                { value: batchTypesConstatns.DOWNLOAD, label: batchTypesConstatns.DOWNLOAD },
                { value: batchTypesConstatns.EDIT, label: batchTypesConstatns.EDIT },
              ]}
              isDisabled={!batchFromState.isBatchEditable}
              // defaultValue={{ value: batchTypesConstatns.CREATE, label: batchTypesConstatns.CREATE }}
            />
          </FormGroup>
          {!batchFromState?.isBatchEditable && (
            <FormGroup>
              <Label>
                Created Date:
              </Label>
              <Input type="text" name="createdDate" value={batchFromState.createdDate} disabled/>
            </FormGroup>
          )}
          <hr/>
          {(batchType === batchTypesConstatns.DOWNLOAD || batchType === batchTypesConstatns.EDIT)&& (
            <>
              {batchFromState?.isBatchEditable && (
              <Row>
                <Col sm={5}>
                
                  <Label className="text-sm-left"><FormattedMessage id="batchForm.defaultSwitch.defaultMessage"/></Label>
                
                </Col>
                <Col sm={3}>
                  <CustomInput
                    type="switch"
                    id="isDefaultBatchOperation"
                    name="isDefaultBatchOperation"
                    checked={batchFromState?.isDefaultBatchOperation}
                    onChange={() => batchFromState.toggleIsDefaultBatchOperation()}
                  />
                </Col>
              </Row>
              )}
            </>)
          }
          {!batchFromState?.isDefaultBatchOperation || batchType === batchTypesConstatns.CREATE ? (
              <>
                <FormGroup>
                  <Label className="text-sm-right"><FormattedMessage id="createQr.groups.defaultMessage"/>:</Label>
                  <GroupSelect groupSelectStore={batchFromState.groupSelectStore} disabled={!batchFromState.isBatchEditable}/>
                </FormGroup>
                {batchFromState?.dimensionSelectViewState?.dimensionsEnabled && batchFromState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => batchFromState.buildDimensions(dimensionSelectStore))}
              </>
            ) : (<></>)
          }

          {batchType === batchTypesConstatns.CREATE && (
            <>
              <FormGroup>
                <Label>
                  Code Experience:
                </Label>
                <Select
                  id="codeExperience"
                  name="codeExperience"
                  onChange={(data)=>batchFromState.setCodeType(data.value)}
                  options={[
                    { value: 'url', label: 'URL' },
                    { value: 'gs1', label: 'GS1' },
                  ]}
                  defaultValue={{ value: 'url', label: 'URL' }}
                  isDisabled={!batchFromState.isBatchEditable}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Domain:
                </Label>
                <PublishDomainSelectView publishDomainSelectState={batchFromState.publishDomainSelectStore} configs={stateContext?.appConfigs?.configs} disabled={!batchFromState.isBatchEditable}/>
              </FormGroup>
              <FormGroup>
                <Label>
                  Tags:
                </Label>
                <TagSelect tagSelectStore={batchFromState.tagSelectStore} disabled={!batchFromState.isBatchEditable}/>
              </FormGroup>
              <FormGroup>
                <Label>
                  Code Status:
                </Label>
                <Select
                  id="codeStatus"
                  name="codeStatus"
                  onChange={(data)=>batchFromState.setCodeStatus(data.value)}
                  options={[
                    { value: 'DRAFT', label: 'DRAFT' },
                    { value: 'PUBLISHED', label: 'PUBLISHED' }
                  ]}
                  defaultValue={{ value: 'PUBLISHED', label: 'PUBLISHED' }}
                  isDisabled={!batchFromState.isBatchEditable}
                />
              </FormGroup>
            </>
          )}
          {(batchType === batchTypesConstatns.DOWNLOAD || batchType === batchTypesConstatns.EDIT) && (
            <>
              {!batchFromState?.isDefaultBatchOperation && (
                  <>
                    <FormGroup>
                      <Label>
                        Tags:
                      </Label>
                      <Row>
                        <Col sm={10}>
                          <TagSelect tagSelectStore={batchFromState.tagSelectStore} disabled={!batchFromState.isBatchEditable}/>
                        </Col>
                        <Col sm={2}>
                          <CustomInput
                            type="switch"
                            id="isFilterTagTypeOR"
                            name="isFilterTagTypeOR"
                            label={formConstants.Mode.OR}
                            checked={batchFromState.isFilterTagTypeOR}
                            onChange={() => batchFromState.selectFilterTagMethod()}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Filter:
                      </Label>
                      <InputGroup className="mb-3">
                        {/* <InputGroupAddon addonType="prepend">
                          {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                        </InputGroupAddon> */}
                        <CodeFilterSelect codeFilterSelectState={batchFromState.codeFilterSelectState}/>
                      </InputGroup>
                    </FormGroup>
                  </>)}



             {batchFromState?.isDefaultBatchOperation && (
              <>
                <FormGroup>
                  <Label>
                    Batch Name:
                  </Label>
                  <InputGroup className="mb-3">
                    <BatchSelect batchSelectStore={batchFromState.batchSelectStore} disabled={!batchFromState.isBatchEditable}/>
                  </InputGroup>
                </FormGroup>
              </>)
              }
              {batchType === batchTypesConstatns.DOWNLOAD && (
                <>
                  <FormGroup>
                      <Label>
                        Designer Template:
                      </Label>
                      <InputGroup className="mb-3">
                        <QrConfigSelect store={batchFromState.qrConfigSelectStore} disabled={!batchFromState.isBatchEditable}/>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Image Format:
                      </Label>
                    <QrDownloadView store={batchFromState} disabled={!batchFromState.isBatchEditable}/>
                  </FormGroup>
                </>
              )}
{/* --------------------------------------------------------------------- Edit Type ------------------------------------------------------------- */}
              {batchType === batchTypesConstatns.EDIT && (
                <>
                  <hr/>
                  <FormGroup>
                    <Label>
                      Edit Type:
                    </Label>
                    <Select id="editType"
                            name="editType"
                            onChange={(data)=>batchFromState.setEditType(data.value)}
                            options={[
                              { value: batchEditTypes.STATUS, label: batchEditTypes.STATUS },
                              { value: batchEditTypes.TAG, label: batchEditTypes.TAG },
                              { value: batchEditTypes.URL, label: batchEditTypes.URL },
                            ]}
                            defaultValue={{ value: batchEditTypes.STATUS, label: batchEditTypes.STATUS }}
                            value = {{ value: batchFromState.editType, label: batchFromState.editType }}
                            isDisabled={!batchFromState.isBatchEditable}
                    />

                  </FormGroup>
                  {batchFromState.editType === batchEditTypes.URL && (
                    <FormGroup>
                      <Label>
                        New URL:
                      </Label>
                      <InputGroup className="mb-3">
                        <Input type="text" name="url" value={batchFromState.editValue} onChange={(e)=>batchFromState.setEditValue(e.target.value)} required disabled={!batchFromState.isBatchEditable}/>
                      </InputGroup>
                    </FormGroup>
                  )}
                  {batchFromState.editType === batchEditTypes.STATUS && (
                    <FormGroup>
                      <Label>
                        Change Status To:
                      </Label>
                      <Select id="status"
                              name="status"
                              onChange={(data)=>batchFromState.setEditValue(data.value)}
                              options={[
                                { value: codeStatus.COMPLETED, label: codeStatus.COMPLETED },
                                { value: codeStatus.PAUSED, label: codeStatus.PAUSED },
                                { value: codeStatus.PUBLISHED, label: codeStatus.PUBLISHED }
                              ]}
                              value = {{ value: batchFromState.editValue, label: batchFromState.editValue }}
                              isDisabled={!batchFromState.isBatchEditable}
                      />
                    </FormGroup>
                  )}
                  {batchFromState.editType === batchEditTypes.TAG && (
                    <>
                        <FormGroup>
                        <Label>
                          Tag Operation:
                        </Label>
                        <Select id="tagOperation"
                                name="tagOperation"
                                onChange={(data)=>batchFromState.setTagOperation(data.value)}
                                options={[
                                  { value: batchEditTag.ADD_TAG, label: batchEditTag.ADD_TAG },
                                  { value: batchEditTag.REPLACE_TAG, label: batchEditTag.REPLACE_TAG },
                                  { value: batchEditTag.REMOVE_TAG, label: batchEditTag.REMOVE_TAG }
                                ]}
                                defaultValue={{ value: batchEditTag.ADD_TAG, label: batchEditTag.ADD_TAG }}
                                value = {{ value: batchFromState.tagOperation, label: batchFromState.tagOperation }}
                                isDisabled={!batchFromState.isBatchEditable}
                                />
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          Tag:
                        </Label>
                        <Select id="newSelectedTags"
                                name="newSelectedTags"
                                onChange={updatedSelectedTagsOptions => {
                                  const updatedSelectedTags = updatedSelectedTagsOptions ? updatedSelectedTagsOptions.map(option => option.value) : [];
                                  batchFromState.setUpdatedSelectedTags(updatedSelectedTags);
                                }}
                                options={availableSelectedTagOptions}
                                value={batchFromState.updatedSelectedTags.map(tag => ({
                                  value: tag.uuid,
                                  label: tag.name
                                }))}
                                isMulti
                                isDisabled={!batchFromState.isBatchEditable}
                                />
                      </FormGroup>
                    </>
                  )}
                </>
              )}
               
              {batchFromState.isBatchEditable && !batchFromState?.isDefaultBatchOperation && (
                  <>
                    <FormGroup>
                      <Label>
                        Total Codes:
                      </Label>
                      {batchFromState.downloadCounts}
                    </FormGroup>
                  </>
                )
              }
            </>
          )} 
          {batchFromState.isBatchEditable && !batchFromState.showTagDownloadBox && (
          <div className="d-flex justify-content-between align-items-center">
            <Nav tabs>
              <NavItem>
                <NavLink className={batchFromState.activeTab === 'files' ? 'active' : ''}
                        onClick={() => batchFromState.setActiveTab('files')}>
                  Select CSV File :
                </NavLink>
              </NavItem>
            </Nav>
            <div>
              {(batchFromState?.selectedCodeType === 'gs1') && (
                <>
                  <a href="https://cdn.scanbuy.com/apps/cmp-v2/files/batch_create_gs1_example_file.csv" download>Download Template</a>
                </>
              )}
              {(batchFromState?.selectedCodeType === 'url') && (
                <>
                  <a href="https://cdn.scanbuy.com/apps/cmp-v2/files/batch_create_url_example_file.csv" download>Download Template</a>
                </>
              )}
              
            </div>
          </div>
          )}
          {!batchFromState.isBatchEditable && batchFromState.batchType === batchTypesConstatns.CREATE && (
            <a href="#" onClick={() => batchFromState.downloadBatchCreateCSV()}>Download Original CSV</a>
          )}
          <TabContent activeTab={batchFromState.activeTab}>
            {batchFromState.isBatchEditable && !batchFromState.showTagDownloadBox && (
            <TabPane tabId="files">
              <FormGroup>
                <StyledDropzone id="dropzone" name="dropzone" setUploadFiles={uploadFiles} />
              </FormGroup>
            </TabPane>)}
            
          </TabContent>
          {batchFromState.isBatchEditable && (
            <FormGroup>
              <Button color="danger" onClick={handleCancel}>Cancel</Button>
              <Button color="primary" className="float-right" onClick={batchFromState.buttonText === 'edit' ? batchFromState.handleConfigModalPopUp() : handleSubmit}>{batchFromState.buttonText}</Button>
            </FormGroup>
          )}
        </Form>
      </CardBody>
      {/* <CardFooter> <FormattedMessage id="batchForm.alertInfo.defaultMessage"/></CardFooter> */}
      {batchFromState.formHasErrors && <div className="alert alert" style={{
          fontSize: "20px",
          backgroundColor: "#faada7",
          width: "80%",
          marginLeft: "10%",
          padding:"5px",
          paddingLeft:"8px",
          paddingRight:"8px"
        }} role="alert">
          <div>{batchFromState.errorMessage}</div>
        </div>}
      {/* <ScanbuyModal
          modalStore={batchFromState.modalStore}
          componentStore={batchFromState.modalComponentStore}
          EmbedComponent={batchFromState.modalComponent}
          size={batchFromState.modalSize}
          className={batchFromState.modalClassName}
        />    */}
    </Card>
  );
});


export default BatchForm;
