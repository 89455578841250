import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {Button, Card, CardBody, CardHeader, Col, InputGroup, InputGroupAddon, Row, Label, FormGroup} from "reactstrap";
import BatchFilterSelect from "../../../elements/filters/BatchFilterSelect";
import ScanbuyTable from "../../../tables/ScanbuyTable";
import {FormattedMessage, useIntl} from "react-intl";
import {RouterLink, useRouterStore, browserHistory} from "mobx-state-router";
/**
 *
 * @param batchListState - BatchListState
 */
const BatchItemListView = observer(({ batchItemListState }) => {
  const routerStore = useRouterStore();
  const intl = useIntl();
  useEffect(() => {
    batchItemListState.refresh()
  }, [batchItemListState])

  return (
    <Card>
      <CardHeader>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm={4}>
            {/* <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                Filter
              </InputGroupAddon>
            </InputGroup> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <ScanbuyTable
              columns={batchItemListState.columns}
              //customStyles={batchItemsListState.customStyles}
              store={batchItemListState.store}
            />
          </Col>
          
        </Row>
        <Button id="back-btn" color="secondary" onClick={browserHistory.goBack}><FormattedMessage
                id="editQr.back.defaultMessage"/></Button>
      </CardBody>
    </Card>
  );
})

export default BatchItemListView;
