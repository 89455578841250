import { RouterState, RouterStore } from "mobx-state-router";
import { BatchList } from "../../models/batch/BatchList";
import BatchFormState from "../../components/codes/batches/BatchFormState";
import BatchListState from "../../components/codes/batches/BatchListState";
import { buildColumns as buildBatchColumns } from "../../pages/codes/batches/List";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import async from "../../components/Async";
import React from "react";
import {BatchFilterSelectState} from "../../components/elements/filters/BatchFilterSelectState";
import BatchItemListState from "src/components/codes/batches/items/BatchItemListState";
import {BatchItemList} from "../../models/batch/BatchItemList";
import { buildColumns as buildBatchItemsColumns } from "../../pages/codes/batches/items/BatchItemsList";
import { CodeModelList } from "../../models/codes/Code";
import { QrDownloadState } from "../../components/qr/qrpreview/qrDownload/QrDownloadState";
import {checkUserPermissions} from "../../utils/requiredRolesCheck";
import {getSnapshot, clone, detach, resolveIdentifier, getRoot, getEnv} from "mobx-state-tree";
import {TagModel} from "../../models/tags/TagModel";
import {batchTypesConstatns, batchEditTypes} from "../../utils/constants";
import {Batch} from "../../models/batch/Batch";
import {capitalizeFirstLetter} from "../../utils/common";

const BatchesPage = async(() => import("../../pages/codes/batches/List"));
const BatchItemsPage = async(() => import("../../pages/codes/batches/items/BatchItemsList"));

export const viewMap = {
  batches: <BatchesPage/>,
  batchItems: <BatchItemsPage/>
}

export const routeDefinitions = [
  {
    path: "batches",
    name: "Batches",
    icon: faMailBulk,
    component: BatchesPage,
    children: null,
    featureFlagRequired: "cmp_ui_batches"
  }
]

export const routeTransitions = [
  {
    name: 'batches',
    pattern: '/batches',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState,
      toState,
      routerStore,
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const batchFilterSelectState = rootStore.rootUiStore.batchFilterSelectState
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState
      const publishDomainSelectStore = rootStore.rootUiStore.publishDomainSelectStore
      const codeFilterSelectState = rootStore.rootUiStore.codeFilterSelectState;
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const batchSelectStore = rootStore.rootUiStore.batchSelectStore;
      const batchList = rootStore.rootUiStore.batchList;
      batchList.clearSelected();
      const tenantStore = rootStore.domainStore.tenantStore;
      groupSelectState?.selectDefaultGroup(tenantStore.defaultGroupUuid);

      codeFilterSelectState.reset();
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]);
      dimensionSelectViewState?.clearSelections()
      dimensionSelectViewState?.setDefaults()
      batchFilterSelectState.reset()
      qrConfigSelectStore.setSelectedGroup(groupSelectState?.selectedGroup?.uuid)
      publishDomainSelectStore.refresh(groupSelectState?.selectedGroup);
      const codes = CodeModelList.create({});
      // batchList.setSelectedBatch("68f1e172-b1de-40bf-b082-e53f24c4acac");
      // const snapshot = getSnapshot(tagSelectStore);
      // const newSelectedTags = {
      //   ...snapshot,
      //   selectStateUuid: 'ntss'
      // }
      let newSelectedTags = []
      tagSelectStore.tagStore.tags.forEach((tag) => {
        const tagModel = resolveIdentifier(TagModel, getRoot(tagSelectStore), tag.uuid);
        if (tagModel) {
          newSelectedTags.push(tag.uuid);
        }
      });

      // const initBatchStore = BatchStore.create({storeUuid: 'btts'});
      // initBatchStore.getBatches();
      // const batchSelectStore = BatchSelectState.create({selectStateUuid: 'bss',})
      // batchSelectStore.setBatches(initBatchStore);

      //batchSelectStore.batchStore.getBatches();
      // batchList.clearSelected();
      // console.log('batchList:', batchList)
      const batchFormState = BatchFormState.create({
        title: "New Batch",
        tagSelectStore: tagSelectStore,
        groupSelectStore: groupSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
        publishDomainSelectStore: publishDomainSelectStore,
        codeFilterSelectState: codeFilterSelectState,
        qrConfigSelectStore: qrConfigSelectStore,
        codeModels: codes,
        batchSelectStore: batchList,
        qrDownloadStore: QrDownloadState.create({}),
        availableNewSelectedTags: newSelectedTags,
        batchType: batchTypesConstatns.CREATE,
      })
      
      const viewState = BatchListState.create({
        store: batchList,
        columns: buildBatchColumns(routerStore, batchList),
        groupSelectStore: groupSelectState,
        batchFormState: batchFormState,
        batchFilterSelectState: batchFilterSelectState
      });

      console.log("Attaching Page State : batchListViewState ")
      await rootStore.rootUiStore.attachPageState('batchListViewState', viewState)
      // load codes filter list
      await batchFormState.fetchFilters();
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      //setTimeout(()=>{rootStore.rootUiStore.detachPageState('batchListViewState')},100)
      const batchList = rootStore.rootUiStore.batchList;
      batchList.clearSelected();
    }
  },
  {
    name: 'batchItems',
    pattern: '/batches/:batchUuid/items',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState,
      toState,
      routerStore,
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const batchFilterSelectState = rootStore.rootUiStore.batchFilterSelectState
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState
      const publishDomainSelectStore = rootStore.rootUiStore.publishDomainSelectStore
      const codeFilterSelectState = rootStore.rootUiStore.codeFilterSelectState;
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const qrConfigStore = rootStore.domainStore.qrConfigStore
      
      const batchList = rootStore.rootUiStore.batchList;
      batchList.clearSelected();
      codeFilterSelectState.reset();
      codeFilterSelectState.setIsDisabled(true);
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]);
      dimensionSelectViewState?.clearSelections()
      dimensionSelectViewState?.setDefaults()
      batchFilterSelectState.reset()
      
      //qrConfigSelectStore.setSelectedGroup(groupSelectState.groupStore?.groups[0].uuid)
      
      publishDomainSelectStore.refresh(groupSelectState.groupStore?.groups[0]);
      const codes = CodeModelList.create({});
      
      const selectedBatch = await getEnv(rootStore).batchManager.fetchBatchDetail(toState.params.batchUuid)

      qrConfigSelectStore.setSelectedConfig(selectedBatch.details.qrConfig)
      
      let batchType = "Create"
      if(selectedBatch.batchType === "CREATE"){
        batchType = batchTypesConstatns.CREATE
      } else if(selectedBatch.batchType === "DOWNLOAD"){
        batchType = batchTypesConstatns.DOWNLOAD
      } else if(selectedBatch.batchType === "EDIT"){
        batchType = batchTypesConstatns.EDIT
      }

      if(selectedBatch.details.owner.groupUuid){
        groupSelectState.selectGroup(selectedBatch.details.owner.groupUuid);
      }

      if(selectedBatch.details.tags){
        tagSelectStore.setSelectedTags(selectedBatch.details.tags);
      }

      if(selectedBatch.details.owner.dimensions){
        dimensionSelectViewState.setDimensions(selectedBatch.details.owner.dimensions);
      }

      const batchItems = BatchItemList.create({
        batchUuid: toState.params.batchUuid
      })

      let selectedTags = []
      tagSelectStore.tagStore.tags.forEach((tag) => {
        const tagModel = resolveIdentifier(TagModel, getRoot(tagSelectStore), tag.uuid);
        if (tagModel) {
          selectedTags.push(tag.uuid);
        }
      });
      
      // if batch is download batch, then need to show image format 
      let imageFormat = ""
      const qrDownloadStore = QrDownloadState.create({})
      if(selectedBatch.batchType == batchTypesConstatns.DOWNLOAD.toUpperCase()) {
        imageFormat = selectedBatch.details.imageFormat
        qrDownloadStore.setImageFormat(imageFormat)
      }

      // if batch is edit batch, then need to set editing value
      let editType = ""
      let editValue = ""
      let tagOperation = ""
      let newSelectedTags = []
      if(selectedBatch.batchType == batchTypesConstatns.EDIT.toUpperCase()) {
        editType = capitalizeFirstLetter(selectedBatch.details.editType.toLowerCase())
        
        if(editType == batchEditTypes.TAG){
          tagOperation = capitalizeFirstLetter(selectedBatch.details.editDetails.tagOperation.toLowerCase())
          tagSelectStore.tagStore.tags.forEach((tag) => {
            if(selectedBatch.details.editType == batchEditTypes.TAG && selectedBatch.details.editDetails.tags.includes(tag.uuid)){
              const tagModel = resolveIdentifier(TagModel, getRoot(tagSelectStore), tag.uuid);
              if (tagModel) {
                newSelectedTags.push(tag.uuid);
              }
            }
          });
        } else if(editType == batchEditTypes.STATUS){
          editValue = selectedBatch.details.editDetails.status
        } else if(editType == batchEditTypes.URL){
          editValue = selectedBatch.details.editDetails.landingUrl
        }
      } 

      // check batch is default batch operation or not
      let isDefaultBatchOperation = false
      if(selectedBatch.details.defaultOperation.hasOwnProperty('batchUuid')){
        isDefaultBatchOperation = true
        batchList.setSelectedBatch(selectedBatch.details.defaultOperation.batchUuid)
      }

      // set created date
      const createdAt = new Date(selectedBatch.createdAt);

      const batchFormState = BatchFormState.create({
        title: selectedBatch.fileName,
        tagSelectStore: tagSelectStore,
        groupSelectStore: groupSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
        publishDomainSelectStore: publishDomainSelectStore,
        codeFilterSelectState: codeFilterSelectState,
        qrConfigSelectStore: qrConfigSelectStore,
        //selectedQrConfig: selectedBatch.details.qrConfig,
        codeModels: codes,
        batchSelectStore: batchList,
        qrDownloadStore: qrDownloadStore,
        codeStatus: selectedBatch.details.codeStatus,

        batchName: selectedBatch.fileName,
        batchType: batchType,
        imageFormat: imageFormat,
        editType: editType,
        editValue: editValue,
        tagOperation: tagOperation,
        availableNewSelectedTags: selectedTags,
        updatedSelectedTags: newSelectedTags,
        isBatchEditable: false,
        isDefaultBatchOperation: isDefaultBatchOperation,
        createdDate: createdAt.toLocaleString(),
        batchUuid: toState.params.batchUuid,
      })
      //batchFormState.selectQrConfig(imageConfig)
      // batchFormState.setOnSelectChanges()
      // console.log('batchDetails', batchDetails.owner)
      const batchItemListState = BatchItemListState.create({
        store: batchItems,
        columns: buildBatchItemsColumns(routerStore),
        //batch: selectedBatch,
        batchFormState: batchFormState,
      })
      console.log("Attaching Page State : batchItemListViewState ")
      await rootStore.rootUiStore.attachPageState('batchItemListViewState', batchItemListState)

    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const codeFilterSelectState = rootStore.rootUiStore.codeFilterSelectState;
      const batchList = rootStore.rootUiStore.batchList;
      codeFilterSelectState.reset();
      batchList.clearSelected();
    }
  }
];
