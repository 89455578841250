import {useDropzone} from 'react-dropzone';
import {useMemo, useState} from 'react';
import React from 'react';
import * as Icon from "react-feather";

export const StyledDropzone = (props) => {
  const { setUploadFiles } = props;
  const [storedFiles, setStoredFiles] = useState([]);
  const fileSelectedHandler = event =>{ console.log(event)}
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const focusedStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    accept:
      ['image/jpeg', 'text/csv']
    ,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setUploadFiles(acceptedFiles)
      setStoredFiles(acceptedFiles);
    }
  });

  // Function to remove a selected file
  const removeFile = (fileName) => {
    const newFiles = storedFiles.filter(file => file.name !== fileName);
    setStoredFiles(newFiles);
    setUploadFiles(newFiles);
  }

  const files = storedFiles.map(file => (
    <li key={file.name}>
      {file.path} - {file.size} bytes
      <span type="button" onClick={() => removeFile(file.name)}>&nbsp;<Icon.Trash2 size={16}/></span>
    </li>
  ));

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} onChange={fileSelectedHandler}/>
        <p>Drag ‘n’ drop CSV file here, or click to select file</p>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </div>
  );
}
