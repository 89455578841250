import {types} from "mobx-state-tree";
export function create(item) {
  return DataExportType.create({
    uuid: item?.type,
    type: item?.type,
    label: item?.label,
    version: item?.version,
    definition: JSON.stringify(item?.definition),
    uiSchema: JSON.stringify(item?.uiSchema),
    scheduler: JSON.stringify(item?.scheduler)
  });
}

export const DataExportType = types.model('DataExportType', {
  uuid: types.identifier,
  type: types.string,
  label: types.string,
  version: types.number,
  definition: types.optional(types.string, ""),
  scheduler: types.optional(types.string, ""),
  uiSchema: types.optional(types.string, ""),
});
