import React from "react";
import { observer } from "mobx-react-lite";
import AsyncSelect from "react-select/async";
import Select from "react-select";

const BatchSelect = observer(({ batchSelectStore, disabled = false }) => {
  console.log("BatchSelect: batchSelectStore: ", batchSelectStore);
  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={batchSelectStore?.batchStore.downloadBatchOptionsValue()}
      isSearchable
      value={{ uuid: batchSelectStore?.selectedBatch ? batchSelectStore?.selectedBatch.uuid:"", fileName: batchSelectStore?.selectedBatch ? batchSelectStore?.selectedBatch.fileName:"" }}
      onChange={(data)=>batchSelectStore?.setSelectedBatch(data)}
      getOptionLabel={option => option.fileName}
      getOptionValue={option => option.uuid}
      placeholder="Select"
      required
      isDisabled={disabled}
    />
  );
});

export default BatchSelect;