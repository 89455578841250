import {CodeModelList} from "../../../models/codes/Code";
import {flow, getEnv, getRoot, IAnyModelType, resolveIdentifier, types} from "mobx-state-tree";
import {TableColumn} from "../../tables/ScanbuyTable";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import Group from "../../../models/Group";
import {ModalState} from "../../elements/modal/GenericModal";
import {GenericToastState} from "../../elements/toast/GenericToastState";
import DeleteState from "../delete/DeleteState";
import { CodeFilterSelectState } from "../../elements/filters/CodeFilterSelectState";
import lodash from "lodash";
import {PageTemplateSelectState} from "../../elements/pageTemplate/PageTemplateSelectState";
import { TagModel as Tag } from "../../../models/tags/TagModel";
import {CodeOwnerFilterSelectState} from "../../elements/filters/CodeOwnerFilterSelectState";
import QrTemplate from "../../../models/QrTemplates";
// import TemplateSelect from "src/components/elements/templates/TemplateSelect";
import {QrTemplateSelectState} from "../../elements/qrtemplates/QrTemplateSelectState";
import {codeBehavior, formConstants} from "../../../utils/constants";
import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import {TagFilterSelectState} from "../../elements/filters/TagFilterSelectState";
import {Modal2State} from "../../elements/modal2/GenericModal2";
import {GroupFilterSelectState} from "../../elements/filters/GroupFilterSelectState";
import {getBase64String} from "../../../utils/common";
import DataExportTypeStore from "../../../stores/domain/DataExportTypeStore";

const CodeListState = types
  .model('CodeListState', {
    store: types.late(() => CodeModelList), // <-- hold the store
    columns: types.maybeNull(types.array(types.late(() => TableColumn))),
    tagSelectStore: types.maybeNull(types.reference(TagSelectState)),
    groupSelectStore: types.maybeNull(types.reference(GroupSelectState)),
    qrtemplateSelectStore:types.maybeNull(types.reference(QrTemplateSelectState)),
    pageTemplateSelectStore: types.maybeNull(types.late(() => types.reference(PageTemplateSelectState))),
    codeFilterSelectState: types.maybeNull(types.reference(CodeFilterSelectState)),
    codeOwnerFilterSelectState: types.maybeNull(types.reference(CodeOwnerFilterSelectState)),
    dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
    tagFilterSelectState: types.maybeNull(types.reference(TagFilterSelectState)),
    groupFilterSelectState: types.maybeNull(types.reference(GroupFilterSelectState)),
    selectedGroups: types.optional(types.array(types.safeReference(Group)), []),
    selectedTemplate:types.optional(types.array(types.safeReference(QrTemplate)),[]),
    modalStore: types.maybeNull(ModalState),
    modal2Store: types.maybeNull(Modal2State),
    toastStore: types.maybeNull(GenericToastState),
    selectedTags: types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
    deleteStateRef: types.maybeNull(DeleteState),
    editModalMode: types.optional(types.boolean, false),
    isFilterTypeOR: types.optional(types.boolean, true),
    isFilterVisible: types.optional(types.boolean, false),
    experienceData: types.optional(types.string, '{}'),
    filterGroup: types.optional(types.boolean, true),
    isElasticSearchEnabled: types.optional(types.boolean, false),
  }).volatile((self) => ({
    modalComponent: types.object,
    modalComponentStore: types.object,
    modalSize: types.str,
    modalClassName: types.str,

    modal2Component: types.object,
    modal2ComponentStore: types.object,
    modal2Size: types.str,
    modal2ClassName: types.str,

    toastComponentStore: types.object,
    row: types.object,
  }))
  .actions((self) => ({
    setDeleteStateRef(delRef){
      self.deleteStateRef = delRef;
    },
    toggleFilterVisible() {
      self.isFilterVisible = !self.isFilterVisible
    },
    setEditModalMode(bool){
      self.editModalMode = bool;
      self.modalClassName = self.editModalMode ? "design-and-view-experience" : "preview-experience";
    },
    setOnSelectChanges() {
      self.groupSelectStore.setMultiSelectConsumer(self.selectGroup)

      self.tagSelectStore?.setConsumer(self.selectTag)

      self.codeFilterSelectState?.setConsumer(self.selectFilter)

      self.codeOwnerFilterSelectState?.setConsumer(self.selectFilter)
      self.dimensionSelectViewState?.setFilterConsumer(self.selectFilter)
      self.tagFilterSelectState?.setConsumer(self.selectFilter)
      self.groupFilterSelectState?.setConsumer(self.selectFilter)
    },
    onSearchChange(txt){
      console.log(txt?.currentTarget?.value);
      const searchText = txt?.currentTarget?.value;
      if(searchText){
        if(searchText.length > 2) {
          self.store.removeSearchParams(["q"]);
          self.store.addSearchParams({ q: searchText })
          self.isElasticSearchEnabled ? self.search() : self.refresh()
        } else {
          self.store.removeSearchParams(["q"])
        }
      } else {
        self.store.removeSearchParams(["q"]);
        self.isElasticSearchEnabled ? self.search() : self.refresh()
      }
    },
    selectFilter(obj, covered){

      const shouldRemoveDimensions = covered.includes("dimensionUuid")
      const regex = shouldRemoveDimensions ? /^dimension/ : undefined;
      self.store.removeSearchParams(covered, regex);

      if(obj){
        const transpiledDimensions = {};
        if(!lodash.isEmpty(obj.dimensions)){
          obj.dimensions.forEach(dimension => {
            const [uuid, value] = dimension.split('_');
            if (transpiledDimensions.hasOwnProperty(`dimension[${uuid}]`)) {
              transpiledDimensions[`dimension[${uuid}]`] += ',' + value;
            } else {
              transpiledDimensions[`dimension[${uuid}]`] = value;
            }
          });
          delete obj.dimensions
        }

        const params = lodash.mapValues(obj,(value, key) => {
          return {}[key] = value.join(',');
        });
        self.store.addSearchParams({...params,...transpiledDimensions})
        self.isElasticSearchEnabled ? self.search() : self.refresh()

        const searchParams = self.store.getSearchParams();
        const currentDimensions = Object.keys(searchParams).filter(key => key.startsWith('dimension['));
        const currentGroupUuid = searchParams.groupUuid ? [searchParams.groupUuid] : [];
        //const currentGroupUuid = obj.groupUuid || [];

        const prevGroupUuid = self.prevGroupUuid || [];
        if (!lodash.isEqual(prevGroupUuid, currentGroupUuid)) {
          self.filterGroup = false;
          self.prevGroupUuid = currentGroupUuid;
          self.fetchFilters();
        }
        if (currentGroupUuid?.length>0 || currentDimensions?.length > 0) {
          self.filterGroup = false;
          self.fetchFilters();
        }
      }
    },
    selectGroup(obj) {
      obj = obj || []
      self.store.addSearchParams({groupUuid: obj.flatMap((row) => row.uuid).join(",")})
      self.isElasticSearchEnabled ? self.search() : self.refresh()
    },
    selectTag(obj) {
      obj = obj || []
      self.store.addSearchParams({tags: obj.flatMap((row) => row.uuid).join(",")})
      self.isElasticSearchEnabled ? self.search() : self.refresh()
    },
    selectFilterMethod() {
        if (!self.isFilterTypeOR) {
          self.isFilterTypeOR = !self.isFilterTypeOR
          self.store.addSearchParams({tagFilterType: formConstants.Mode.OR})
        } else {
          self.isFilterTypeOR = !self.isFilterTypeOR
          self.store.addSearchParams({tagFilterType: formConstants.Mode.AND})
        }
      self.isElasticSearchEnabled ? self.search() : self.refresh()
    },
    setColumns(col) {
      self.columns = col
    },
    handleExportClick(routerStore) {
      const exportType = "default";

      const dataExportTypeStore = resolveIdentifier(DataExportTypeStore, getRoot(self), 'dets');
      const defaultExportSchema = dataExportTypeStore.dataExportTypes.find(e => e.type === exportType);

      if (lodash.isEmpty(defaultExportSchema)) {
        console.error("'default' data-export type not in root");
        routerStore.goTo('dashboard');
      }

      const defaultExportUISchema = JSON.parse(defaultExportSchema.uiSchema);
      const allQRColumns = defaultExportUISchema?.columns?.["ui:options"]?.columnOptions?.find(op => op.label === "QR Codes")?.options;

      const columnsToSelect = ["Code Name", "Code Type", "Code URL", "Created At", "Group Name", "Landing URL", "Status", "Updated At"];
      const selectedFilter = self.store.getSearchParams();

      const filterOnColumn = [];
      let selectedColumns = allQRColumns.filter(col => columnsToSelect.includes(col.label));
      let tags = [];
      let useOr = false;
      if (!lodash.isEmpty(selectedFilter)) {
        const createColumnFilter = (name, condition, value) => {
          return {
            name,
            condition,
            value
          };
        };

        const filteredDimensions = Object.keys(selectedFilter).filter(k => k.startsWith('dimension'));
        const parentDimensionUuids = filteredDimensions.map(d => d.match(/\[(.*?)\]/)[1]);

        const filterKeys = [
          {columnName: 'status', key: 'status', condition: '='},
          {columnName: 'shorturldomain', key: 'domain', condition: '='},
          {columnName: 'createdby', key: 'createdBy', condition: '='},
          {columnName: 'codetype', key: 'type', condition: '='},
          {columnName: 'codename', key: 'q', condition: 'like'},
          {columnName: 'groupuuid', key: 'groupUuid', condition: '='},
          // TODO: uncomment this to add filters once we have respective tables / columns
          // { columnName: 'tags_cmpv2.uuid', key: 'tags', condition: '=' },
          // { columnName: 'qrcodes_cmpv2.behavior', key: 'behavior', condition: '=' },
          // ...(parentDimensionUuids?.map(uuid => ({ columnName: 'dimensions_cmpv2.uuid', key: `dimension[${uuid}]`, condition: '=' })) || []),
        ];

        // selectedFilter: {k: string || comma seperated string}
        // filterKeys[].key is mapped with selectedFilter.k
        // if there's a match then we add the filter on filterKeys[].columnName

        filterKeys.forEach(({columnName, key, condition}) => {
          if (Object.keys(selectedFilter).includes(key)) {
            // if user selects the filter we check the value and add to data-export filters
            const value = selectedFilter[key];
            if (value.includes(',')) {
              useOr = true;
              value.split(',').forEach((v) => {
                filterOnColumn.push(createColumnFilter(columnName, condition, v));
              });
            } else {
              filterOnColumn.push(createColumnFilter(columnName, condition, selectedFilter[key]));
            }
          }
        });
        tags = selectedFilter["tags"]?.split(",");
      }

      const item = {
        action: 'create',
        type: exportType,
        name: "QR Codes Base Export",
        hideActions: getBase64String(["edit"]),
        ...(tags?.length && {tags: getBase64String(tags)}), // this will add tags to the data export; it doesn't add tag filters on the export
        definition: getBase64String({
          columns: selectedColumns.map(col => col.value),
          filter: {
            toggle: Boolean(filterOnColumn.length),
            definition: {
              onColumns: filterOnColumn
            }
          },
          useOr: useOr,
          orderBy: {
            toggle: false
          }
        })
      };
      routerStore.goTo('/data/exports/create',{
        queryParams: item
      });
    },
  fetchAppliedImage:flow(function* f() {

  }),
    handleRowClick(component, title, componentStore, size = "",className = "",row) {
      self.modalComponentStore = componentStore;
      self.modalComponent = component;
      self.modalSize=size;
      self.modalClassName=className;
      self.row = row
      self.modalStore.toggle()
      self.modalStore.handleTitle(title)
    },
    handleModal2PopUp(component, title, componentStore, size = "", className = "") {
      self.modal2ComponentStore = self[componentStore];
      self.modal2Component = component;
      self.modal2Size = size;
      self.modal2ClassName = className;
      self.modal2Store.toggle()
      self.modal2Store.handleTitle(title)
    },
    updateExperienceData(data) {
      self.experienceData = JSON.stringify(data)
    },
    handleClick(componentStore,component){
      self.modalComponent = component;
      self.modalComponentStore = componentStore;
      self.modalStore.handleTitle(componentStore.title)
      self.modalStore.toggle()
    },
    refresh() {
      self.store.fetchAll()
    },
    search () {
      self.store.searchResults()
    },
    addRow(data) {
      //? P / ...
    },
    deleteRow(uuid) {
      console.log("deleteRow", uuid);
      // self.store.items = self.store.items.filter(item => item.uuid !== uuid)
      // commented out above is a filter that is not remounting component properly, having something to do with mobX,
      // therefore we fetchAll for now
      self.store.fetchAll()
    },
    afterCreate() {
      self.modalComponent = (<></>)
      self.modalComponentStore = {}
      // console.log("Instantiated " + getType(self).name)
      // self.groupSelectStore.setConsumer((g)=> {
      //   self.store.addSearchParams({ groupUuid: g.uuid })
      //   self.refresh()
      // })
      //
      // self.tagSelectStore.setConsumer((selectedTags)=> {
      //   const tagUuids = (selectedTags || []).map((val)=>{
      //     return val.value.uuid
      //   })
      //   self.store.addSearchParams({ tags: tagUuids })
      //   self.refresh()
      // })
    },
    publishRow(obj) {
      self.store.publish(obj);
    },
    fetchRow(){
      self.store.fetchAll();
    },
    fetchFilters() {
      self.store.fetchAllFilters();
    }
  })).views( (self) => ({
    shouldRenderSecondTabInPreview() {
      return self.row.behavior === codeBehavior.DYNAMIC
    },
    shouldRenderCopyUrl(){
      return self.row?.behavior !== codeBehavior.STATIC
    }
}))

export default CodeListState
