export const formConstants = {
  Mode: {
    CREATE: "Create",
    EDIT: "Edit/View",
    VIEW: "VIEW",
    DELETE: "Delete",
    OR: "OR",
    AND: "AND"
  }
};

export const codeTypes = {
  URL: "url",
  GS1: "gs1",
  APP: "appLink",
  CALL: "tel",
  EMAIL: 'mailto',
  EVENT: 'vevent',
  MINILANDINGPAGE: 'html5',
  MENULITE: "menulite",
  NOTE: "note",
  SMS: "sms",
  MOBILEWALLET: "passpage",
  SKYPE: "skype",
  VCARD: "vcard",
  WHATSAPP: "wa",
  WIFI: "wifi",
  ZOOM: 'zoom',
  SocialMedia: "socialMedia",
}
export const codeBehavior = {
  STATIC: "STATIC",
  DYNAMIC: "DYNAMIC"
}

export const codeTypesWithBehavior = {
  URL: codeTypes.URL + "|" + codeBehavior.DYNAMIC,
  APP: codeTypes.APP + "|" + codeBehavior.DYNAMIC,
  CALL: codeTypes.CALL + "|" + codeBehavior.DYNAMIC,
  EMAIL: codeTypes.EMAIL + "|" + codeBehavior.DYNAMIC,
  EVENT: codeTypes.EVENT + "|" + codeBehavior.DYNAMIC,
  MINILANDINGPAGE: codeTypes.MINILANDINGPAGE + "|" + codeBehavior.DYNAMIC,
  NOTE: codeTypes.NOTE + "|" + codeBehavior.DYNAMIC,
  SMS: codeTypes.SMS + "|" + codeBehavior.DYNAMIC,
  MOBILEWALLET: codeTypes.MOBILEWALLET + "|" + codeBehavior.DYNAMIC,
  SKYPE: codeTypes.SKYPE + "|" + codeBehavior.DYNAMIC,
  VCARD: codeTypes.VCARD + "|" + codeBehavior.DYNAMIC,
  WHATSAPP: codeTypes.WHATSAPP + "|" + codeBehavior.DYNAMIC,
  WIFI: codeTypes.WIFI + "|" + codeBehavior.DYNAMIC,
  ZOOM: codeTypes.ZOOM + "|" + codeBehavior.DYNAMIC,
  SocialMedia: codeTypes.SocialMedia + "|" + codeBehavior.DYNAMIC,
  MENULITE: codeTypes.MENULITE + "|" + codeBehavior.DYNAMIC,
}

export const Roles = {
  VIEW: "VIEW",
  DRAFT: "DRAFT",
  PUBLISH: "PUBLISH",
  BATCH: "BATCH",
  ADVANCED: "ADVANCED",
  ANALYTICS: "ANALYTICS",
  ALL: "ALL",
}

const CODE_MANAGE_SET = [Roles.DRAFT, Roles.PUBLISH, Roles.BATCH, Roles.ALL]
const BATCH_MANAGE_SET = [Roles.BATCH, Roles.ALL]
const ANALYTICS_MANAGE_SET = [Roles.ANALYTICS, Roles.ALL]
export const ADVANCED_SETTINGS_MANAGE_SET = [Roles.ADVANCED, Roles.ALL]
export const CODE_PUBLISH_SET = [Roles.PUBLISH,Roles.BATCH, Roles.ALL]
const withViewRole = (roles) => [Roles.VIEW, ...roles];

export const RoleSet = { //TODO: Maybe move this to db?
  viewCode: withViewRole(CODE_MANAGE_SET),
  createCode: CODE_MANAGE_SET,
  editCode: CODE_MANAGE_SET,
  deleteCode: CODE_MANAGE_SET,
  viewQrDesign: withViewRole(ADVANCED_SETTINGS_MANAGE_SET),
  qrDesigner: ADVANCED_SETTINGS_MANAGE_SET,
  editQrDesign: ADVANCED_SETTINGS_MANAGE_SET,
  createParameterSet: ADVANCED_SETTINGS_MANAGE_SET,
  editParameterSet: ADVANCED_SETTINGS_MANAGE_SET,
  viewParameterSet: withViewRole(ADVANCED_SETTINGS_MANAGE_SET),
  viewRuleSet: withViewRole(ADVANCED_SETTINGS_MANAGE_SET),
  createRuleSet: ADVANCED_SETTINGS_MANAGE_SET,
  editRuleSet: ADVANCED_SETTINGS_MANAGE_SET,
  viewTag: withViewRole(ADVANCED_SETTINGS_MANAGE_SET),
  createTag: ADVANCED_SETTINGS_MANAGE_SET,
  editTag: ADVANCED_SETTINGS_MANAGE_SET,
  deleteTag: ADVANCED_SETTINGS_MANAGE_SET,
  viewUtm: withViewRole(ADVANCED_SETTINGS_MANAGE_SET),
  createUtm: ADVANCED_SETTINGS_MANAGE_SET,
  editUtm: ADVANCED_SETTINGS_MANAGE_SET,
  reports: ANALYTICS_MANAGE_SET,
  superset: ANALYTICS_MANAGE_SET,
  batches: BATCH_MANAGE_SET,
  batchItems: BATCH_MANAGE_SET,
  "/data/exports": withViewRole(ANALYTICS_MANAGE_SET),
  "/data/exports/create": withViewRole(ANALYTICS_MANAGE_SET),
  "/data/exports/edit/:id": withViewRole(ANALYTICS_MANAGE_SET),
  "/data/exports/delete/:id": withViewRole(ANALYTICS_MANAGE_SET),
}

export const MergedRoutes = new Map([ // map of routes which have edit and view in same component
  ['editCode', 'viewCode'],
  ['editQrDesign', 'viewQrDesign'],
  ['editParameterSet','viewParameterSet'],
  ['editRuleSet','viewRuleSet'],
  ['editTag','viewTag'],
  ['editUtm','viewUtm'],
]);


export const batchTypesConstatns = {
  CREATE: "Create",
  DOWNLOAD: "Download",
  EDIT: "Edit"
}

export const batchEditTypes = {
  URL: "Url",
  STATUS: "Status",
  TAG: "Tag"
}

export const batchEditTag = {
  ADD_TAG: "Add",
  REPLACE_TAG: "Replace",
  REMOVE_TAG: "Remove"
}

export const codeStatus = {
  COMPLETED: "Completed",
  PAUSED: "Paused",
  PUBLISHED: "Published"
}
