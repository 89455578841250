import { IAnyModelType, types } from "mobx-state-tree";
import { BatchItemList } from "../../../../models/batch/BatchItemList";
import { TableColumn } from "../../../tables/ScanbuyTable";
import { Batch } from "../../../../models/batch/Batch";
import BatchFormState from "../BatchFormState";


const BatchItemListState = types
  .model('BatchItemListState',{
    store: types.late(() => BatchItemList), // <-- hold the store
    columns: types.array(types.late(() => TableColumn)),
    batchFormState: types.maybe(types.late(() =>BatchFormState)),
    //,style:
    //groups
    //
  })
  .actions((self) => ({
    refresh(){
      self.store.fetchAll()
    },
    addRow(data) {
      //? P / ...
    },
    deleteRow(row) {

    },
    afterCreate() {

    }
  }))


export default BatchItemListState
