import React, {useContext, useState} from 'react';
import BatchItemListView from 'src/components/codes/batches/items/BatchItemListView';
import { observer } from "mobx-react-lite";
import { StateContext } from "../../../../App";
import { RouterLink } from "mobx-state-router";
import { TableColumn } from "../../../../components/tables/ScanbuyTable";
import Header from "../../../../components/Header";
import TableActionsDropdown from "../../../../components/tables/TableActionsDropdown";
import ScanbuyModal, {ModalState} from '../../../../components/elements/modal/GenericModal';
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Container,
    DropdownItem,
    Form,
    FormGroup,
    Row, CardBody, Card, Button
  } from 'reactstrap';
import {FormattedMessage} from "react-intl";
import BatchForm from '../../../../components/codes/batches/BatchForm';

export const buildColumns = (routerStore) => {
    const {rootStore} = routerStore.options;
    const c1 = TableColumn.create({ name: 'uuid', property: 'uuid' });
    c1.setSelector((row) => row.uuid);

    const c2 = TableColumn.create({
        name: 'Status',
        property: 'status',
        sortable: true,
        sortField: 'status',
        selector: 'status',
    })
    c2.setSelector((row)=>{
        const status = row.status;
        if (status === 'FAILED') {
            return (<><div><span style={{color: 'red'}}>{status}</span></div></>)
        } else {
            return (<div>{status}</div>)
        }
    });

    const c3 = TableColumn.create({ name: 'name', property: 'name' });
    c3.setSelector((row) => {
        return row.qr.name;
    });

    return [
        c3,
        c2
        ];

}

const BatchItemsPage = observer((props) => {
    const stateContext = useContext(StateContext);
    const batchItemListState = stateContext.rootUiStore.batchItemListViewState
    console.log("BatchItemsPage: batchItemListState: ", batchItemListState)
    return (
        <Container fluid>
            <Header>
                <Breadcrumb>
                <BreadcrumbItem>
                    <RouterLink routeName="dashboard" ><FormattedMessage id="routes.home.defaultMessage"/></RouterLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <RouterLink routeName="codes" ><FormattedMessage id="routes.codes.defaultMessage"/></RouterLink>
                </BreadcrumbItem>
                <BreadcrumbItem active><FormattedMessage id="routes.batches.defaultMessage"/></BreadcrumbItem>
                </Breadcrumb>
                {/* <ScanbuyModal
                modalStore={modalStore}
                EmbedComponent={x} /> */}
            </Header>
            <Row>
                <Col sm={8}>
                    <BatchItemListView batchItemListState={batchItemListState}/>
                </Col>
                <Col sm={4}>
                    <Card>
                        <CardBody>
                            <FormGroup>
                                {/* <Row>
                                    <Col>Batch Name:</Col>
                                    <Col>{batchItemListState.batch.fileName}</Col>
                                </Row>
                                <Row>
                                    <Col>Batch Type:</Col>
                                    <Col>{batchItemListState.batch.batchType}</Col>
                                </Row> */}
                                <BatchForm batchFromState={batchItemListState.batchFormState}  />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
})

export default BatchItemsPage;
