import React from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import {
  Label
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";

const PublishDomainSelectView = observer(({ publishDomainSelectState, configs, disabled = false }) => {
    return (
      <>
        <Select
          sm={3}
          className="react-select-container"
          classNamePrefix="react-select"
          options={publishDomainSelectState?.publishDomainOptionValues()}
          id="selectedPublishDomain"
          value={publishDomainSelectState?.selectedPublishDomain || null}
          defaultValue={publishDomainSelectState.selectedPublishDomain ? publishDomainSelectState.selectedPublishDomain : publishDomainSelectState.getFirstPublishedDomain()}
          //inputValue={createCodeInput.selectedDomain}
          isSearchable
          onInputChange={(data) => publishDomainSelectState?.searchPublishDomain(data)}
          onChange={(data) => publishDomainSelectState?.selectPublishDomain(data)}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.uuid}
          placeholder="Select"
          isDisabled={disabled}
        />
        { configs.prefix_shorturl_enabled && publishDomainSelectState?.selectedPublishDomain?.prefixes?.length > 0 &&
        <>
          <Label sm={2} className="text-sm-right"><FormattedMessage id="createQr.domainPrefix.defaultMessage"/></Label>
          <Select 
            sm={2}
            className="react-select-container"
            classNamePrefix="react-select"
            id = "selectedPrefix"
            options={publishDomainSelectState?.prefixOptionValues()}
            value={publishDomainSelectState.selectedPublishDomain.selectedPrefix}
            defaultValue={publishDomainSelectState.selectedPublishDomain.selectedPrefix}
            onChange={(data) => publishDomainSelectState.selectedPublishDomain.selectPrefix(data)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.prefix}
          
          />
        </>
        }
      </>
    );
});

export default PublishDomainSelectView;
