import HttpClient from "../../services/HttpClient";

class DataExportManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient();
    this.dataExportURL = `/data/exports`;
    this.dataExportTypeURL = `/data/export-types`;
    this.dataExportFiltersURL = '/sites/filters/data-export';
  }

  handleError(instance, error) {
    instance?.handleHasErrors(true);
    instance?.handleErrorMessage(error?.message);
    console.error(`Error: ${error?.message}`, error);
    return error;
  }

  createRequest(instance) {
    const groups = instance?.groupSelectStore?.selectedGroups?.flatMap((group) => group?.uuid);
    const payload = {
      "name": instance?.name,
      "definition": instance?.getFormData(),
      "schedule": instance?.getExportScheduleFormData(),
      "type": instance?.dataExportTypeSelected?.type,
      "tags": instance?.tagSelectStore?.selectedTags?.flatMap((tag) => tag?.uuid),
      "isExclusivelyGroupOwned": instance?.isExclusivelyGroupOwned,
      "owner": {
        ...(groups?.length && {"groupUuid": groups[0]}),
        ...(groups?.length && {"groups": groups}),
        ...(instance?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled &&
          { "dimensions": instance?.getSelectedDimensions() })
      },
      "hideActions": instance?.hideActions
    };
    console.log(payload);
    return payload;
  }

  async fetchAllForMe(queryParams, instance) {
    let response;
    try {
      response = await this.httpClient.get(`/me${this.dataExportURL}`, queryParams);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async fetchByUUID(uuid, queryParams, instance) {
    let response;
    try {
      response = await this.httpClient.get(`${this.dataExportURL}/${uuid}`, queryParams);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async fetchAllTypes(queryParams, instance) {
    let response;
    try {
      response = await this.httpClient.get(this.dataExportTypeURL, queryParams);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async fetchAllFilters(queryParams, instance) {
    let response;
    try {
      response = await this.httpClient.get(this.dataExportFiltersURL, queryParams);;
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async getDownloadLink(uuid, instance) {
    let response;
    try {
      response = await this.httpClient.get(`${this.dataExportURL}/${uuid}/download-link`);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async performAction(uuid, action, instance) {
    let response;
    try {
      response = await this.httpClient.get(`${this.dataExportURL}/${uuid}/actions/${action}`);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }
  async delete(uuid, instance) {
    let response;
    try {
      response = await this.httpClient.destroy(`${this.dataExportURL}/${uuid}`);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }
  async save(instance) {
    let response;
    try {
      response = await this.httpClient.post(this.dataExportURL, this.createRequest(instance));
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async update(uuid, instance) {
    let response;
    try {
      response = await this.httpClient.put(`${this.dataExportURL}/${uuid}`, this.createRequest(instance));
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }
}

export default DataExportManager;
