import Ajv from "ajv";
import addFormats from "ajv-formats";

export function setDefaultBackgroundColor(extend) {
    if(!extend.hasOwnProperty('backgroundColor') && !extend.hasOwnProperty('transparentBackground')) {
        extend.backgroundColor = "#ffffff"
    } else if (!extend.hasOwnProperty('backgroundColor') && extend.transparentBackground === false ) {
        extend.backgroundColor = "#ffffff"
    }
    return extend
}

export function calculateAllEyeBallRotationForEachEyeBall(eyeBallsRotatedAngle) {
  let ball1Rotation = eyeBallsRotatedAngle > 180 ? (eyeBallsRotatedAngle - 360) : eyeBallsRotatedAngle;
  let ball2Rotation = eyeBallsRotatedAngle < 180 ? (-eyeBallsRotatedAngle - 90) : (270 - eyeBallsRotatedAngle);
  if(Math.abs(ball2Rotation) > 180 ){
    ball2Rotation = 360 - Math.abs(ball2Rotation)
  }
  let ball3Rotation = eyeBallsRotatedAngle > 180 ? (-270 - ball1Rotation) : (90 - eyeBallsRotatedAngle);
  if(Math.abs(ball3Rotation) > 180 ){
    ball3Rotation = 360 - Math.abs(ball3Rotation)
  }
  return [ball1Rotation, ball2Rotation, ball3Rotation]
}

export function setEyesAndModules(extend) {
    console.log("setEyesAndModules", JSON.stringify(extend))
    if(!extend.hasOwnProperty('changeIndividualEyes') || extend.changeIndividualEyes === false) {
        const eyeLidAngle = extend.allEyeLidsRotation
        if(eyeLidAngle === 90) {
            extend.eye1Rotation = ["fv"]
            extend.eye1RotationAngle = 90
            extend.eye2Rotation = ["fh","fv"]
            extend.eye2RotationAngle = 90
            extend.eye3Rotation = []
            extend.eye3RotationAngle = 90
        } else if(eyeLidAngle === 180) {
            extend.eye1Rotation = ["fh","fv"]
            extend.eye1RotationAngle = 180
            extend.eye2Rotation = ["fv"]
            extend.eye2RotationAngle = 180
            extend.eye3Rotation = ["fh"]
            extend.eye3RotationAngle = 180
        } else if(eyeLidAngle === 270) {
            extend.eye1Rotation = ["fh"]
            extend.eye1RotationAngle = 270
            extend.eye2Rotation = []
            extend.eye2RotationAngle = 270
            extend.eye3Rotation = ["fh", "fv"]
            extend.eye3RotationAngle = 270
        } else {
            extend.eye1Rotation = []
            extend.eye1RotationAngle = 0
            extend.eye2Rotation = ["fh"]
            extend.eye2RotationAngle = 0
            extend.eye3Rotation = ["fv"]
            extend.eye3RotationAngle = 0
        }
        if(extend.hasOwnProperty('allEyeBallsRotation') ) {

            let eyeBallsRotatedAngle = extend.allEyeBallsRotation
            let ballRotations = calculateAllEyeBallRotationForEachEyeBall(eyeBallsRotatedAngle)
            extend.ball1Rotation = [ballRotations[0]]
            extend.ball2Rotation = [ballRotations[1]]
            extend.ball3Rotation = [ballRotations[2]]
            extend.ball1RotationAngle = ballRotations[0]
            extend.ball2RotationAngle = ballRotations[1]
            extend.ball3RotationAngle = ballRotations[2]
        }

    } else if(extend.hasOwnProperty('changeIndividualEyes') && extend.changeIndividualEyes === true) {
        extend.allEyeLidsRotation = 0
        extend.allEyeBallsRotation = 0
    }
    return extend
}
export function urlValidator(url, urlPattern = "") {
  if(url){
    const pattern = urlPattern ? new RegExp(urlPattern) : /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=!,| ]*)$/i;
    const isUrlValid = pattern.test(url);

    if (!isUrlValid) {
      throw new Error("Invalid URL");
    }
    return isUrlValid;
  }
}
export function  addHttpsIfNeeded(url) {
  if (url && !/^https?:\/\//i.test(url)) {
    return 'http://' + url;
  }
  return url;
}

export const getJSONFromBase64 = (base64String, defaultVal={}) => {
  if (base64String) {
    try {
      return JSON.parse(Buffer.from(base64String, 'base64').toString('ascii'));
    } catch (err) {
      console.error(err);
    }
  }
  return defaultVal;
};

export const getBase64String = (value) => {
  try {
    return Buffer.from(JSON.stringify(value)).toString('base64');
  } catch (err) {
    console.error(err);
  }
  return null;
};


export const isJSONSchemaValid = (schema, data) => {
  try {
    const ajv = new Ajv({allErrors: true, validateFormats: false});
    addFormats(ajv);
    const validate = ajv.compile(schema);
    const valid = validate(data);
    if (!valid) {
      console.error(`Error: Failed to validate form data`, validate.errors);
    }
    return valid;
  } catch (err) {
    console.error(err);
  }
  return false;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hasNoSpaces = (str) => {
  return !str.includes(' ');
}
