import {types} from "mobx-state-tree";
import {calculateAllEyeBallRotationForEachEyeBall} from "../../../../utils/common";

const EyesAndModulesState = types.model('EyesAndModulesState', {
  openModule:types.optional(types.boolean, false),
  dataShape: types.optional(types.integer, 0),

  openPrimaryEye:types.optional(types.boolean, false),
  changeIndividualEyes:types.optional(types.boolean, false),
  eyePrimaryShape: types.optional(types.integer, 0),
  ballPrimaryShape: types.optional(types.integer, 0),

  openEye1:types.optional(types.boolean, false),
  eye1Shape: types.optional(types.integer, 0),
  ball1Shape: types.optional(types.integer, 0),
  eye1Rotation: types.optional(types.array(types.string), []),
  ball1Rotation: types.optional(types.array(types.integer), []),
  openEyes1Rotation: types.optional(types.boolean, false),
  eye1RotationAngle: types.optional(types.integer, 0),
  ball1RotationAngle: types.optional(types.integer, 0),

  openEye2:types.optional(types.boolean, false),
  eye2Shape: types.optional(types.integer, 0),
  ball2Shape: types.optional(types.integer, 0),
  eye2Rotation: types.optional(types.array(types.string), []),
  ball2Rotation: types.optional(types.array(types.integer), []),
  openEyes2Rotation: types.optional(types.boolean, false),
  eye2RotationAngle: types.optional(types.integer, 0),
  ball2RotationAngle: types.optional(types.integer, 0),

  openEye3:types.optional(types.boolean, false),
  eye3Shape: types.optional(types.integer, 0),
  ball3Shape: types.optional(types.integer, 0),
  eye3Rotation: types.optional(types.array(types.string), []),
  ball3Rotation: types.optional(types.array(types.integer), []),
  openEyes3Rotation: types.optional(types.boolean, false),
  eye3RotationAngle: types.optional(types.integer, 0),
  ball3RotationAngle: types.optional(types.integer, 0),

  moduleScale: types.optional(types.integer, 100),
  moduleRotation: types.optional(types.boolean, false),
  // angle: types.optional(types.integer, 0)
  openAllEyesRotation: types.optional(types.boolean, false),
  allEyeLidsRotation: types.optional(types.integer, 0),
  allEyeBallsRotation: types.optional(types.integer, 0)
})
  .volatile(self => ({
    notifyDesigner: types.f,
    notifyPreview:types.f,
  }))
  .actions((self) => ({
    setDesignerConsumer(f) {
      self.notifyDesigner = f
    },
    setPreviewConsumer(f){
      self.notifyPreview = f
    },
    handleShapeChange(group, shape, logoStore) {
      self[group] = shape
      self.notifyDesigner(group,shape)
      if(self.dataShape !== 2 && logoStore && logoStore.backgroundImage && logoStore.backgroundImage !== "") {
        self.moduleScale = 75
        self.notifyDesigner("moduleScale", self.moduleScale)
      }

    }, handleAngleChange(e) {
      if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 360) {
        self.angle = parseInt(e.target.value)
      }
    },
    toggle(key){
      self[key] = !self[key]
    },
    toggleChangeIndividualEyes() {
      self.changeIndividualEyes = !self.changeIndividualEyes
      !self.changeIndividualEyes && self.applyToAll()
      self.notifyDesigner("changeIndividualEyes", self.changeIndividualEyes)
      self.changeIndividualEyes && self.applyIndividuals()
      self.notifyDesigner("changeIndividualEyes", self.changeIndividualEyes)
      self.notifyPreview()
      self.openPrimaryEye = false
      self.openEye1 = false
      self.openEye2 = false
      self.openEye3 = false
      if(self.openAllEyesRotation) {
        self.openAllEyesRotation = false
      }
    },

    applyToAll(){

      self.notifyDesigner("eye1Shape", self.eyePrimaryShape)
      self.notifyDesigner("eye2Shape", self.eyePrimaryShape)
      self.notifyDesigner("eye3Shape", self.eyePrimaryShape)

      self.notifyDesigner("ball1Shape", self.ballPrimaryShape)
      self.notifyDesigner("ball2Shape", self.ballPrimaryShape)
      self.notifyDesigner("ball3Shape", self.ballPrimaryShape)

      if(self.allEyeLidsRotation === 90) {
        self.notifyDesigner("eye1Rotation",  ["fv"])

        self.notifyDesigner("eye2Rotation",  ["fh","fv"])

        self.notifyDesigner("eye3Rotation", [])

      } else if(self.allEyeLidsRotation === 180) {
          self.notifyDesigner("eye1Rotation",  ["fh","fv"])
          self.notifyDesigner("eye2Rotation",  ["fv"])
          self.notifyDesigner("eye3Rotation",  ["fh"])

      } else if(self.allEyeLidsRotation === 270) {
          self.notifyDesigner("eye1Rotation",  ["fh"])

          self.notifyDesigner("eye2Rotation",  [])

          self.notifyDesigner("eye3Rotation",  ["fh", "fv"])

      } else {
          self.notifyDesigner("eye1Rotation",  [])

          self.notifyDesigner("eye2Rotation",  ["fh"])

          self.notifyDesigner("eye3Rotation",  ["fv"])

      }
      self.notifyDesigner("eye1RotationAngle", self.allEyeLidsRotation)
      self.notifyDesigner("eye2RotationAngle", self.allEyeLidsRotation)
      self.notifyDesigner("eye3RotationAngle", self.allEyeLidsRotation)
      if(self.allEyeBallsRotation !== 0) {
        self.setAllEyeBallRotation(self.allEyeBallsRotation)
      }
    },
    applyIndividuals(){

      self.notifyDesigner("eye1Shape", self.eye1Shape)
      self.notifyDesigner("eye2Shape", self.eye2Shape)
      self.notifyDesigner("eye3Shape", self.eye3Shape)

      self.notifyDesigner("eye1Rotation", self.eye1Rotation)
      self.notifyDesigner("eye2Rotation", self.eye2Rotation)
      self.notifyDesigner("eye3Rotation", self.eye3Rotation)

      self.notifyDesigner("eye1RotationAngle", self.eye1RotationAngle)
      self.notifyDesigner("eye2RotationAngle", self.eye2RotationAngle)
      self.notifyDesigner("eye3RotationAngle", self.eye3RotationAngle)

      self.notifyDesigner("ball1Shape", self.ball1Shape)
      self.notifyDesigner("ball2Shape", self.ball2Shape)
      self.notifyDesigner("ball3Shape", self.ball3Shape)

      self.notifyDesigner("ball1Rotation", self.ball1Rotation)
      self.notifyDesigner("ball2Rotation", self.ball2Rotation)
      self.notifyDesigner("ball3Rotation", self.ball3Rotation)

      self.notifyDesigner("ball1RotationAngle", self.ball1RotationAngle)
      self.notifyDesigner("ball2RotationAngle", self.ball2RotationAngle)
      self.notifyDesigner("ball3RotationAngle", self.ball3RotationAngle)

    },
    handleModuleScale(e) {
      self.moduleScale = +e.target.value
      self.notifyDesigner("moduleScale",self.moduleScale)
    },
    toogleModuleRotation() {
      self.moduleRotation = !self.moduleRotation
      self.notifyDesigner("moduleRotation",self.moduleRotation)
    },
    handleAllEyeLidsRotation(e) {
      const rotatedAngle = +e.target.value
      if(rotatedAngle === 90) {
        self.allEyeLidsRotation = 90
        self.eye1Rotation = ["fv"]
        self.eye2Rotation = ["fh","fv"]
        self.eye3Rotation = []
      } else if(rotatedAngle === 180) {
        self.allEyeLidsRotation = 180
        self.eye1Rotation = ["fh","fv"]
        self.eye2Rotation = ["fv"]
        self.eye3Rotation = ["fh"]
      } else if(rotatedAngle === 270) {
        self.allEyeLidsRotation = 270
        self.eye1Rotation = ["fh"]
        self.eye2Rotation = []
        self.eye3Rotation = ["fh", "fv"]
      } else {
        self.allEyeLidsRotation = 0
        self.eye1Rotation = []
        self.eye2Rotation = ["fh"]
        self.eye3Rotation = ["fv"]
      }
      self.notifyDesigner("allEyeLidsRotation",rotatedAngle)
      self.notifyDesigner("eye1Rotation",self.eye1Rotation)
      self.notifyDesigner("eye1RotationAngle", rotatedAngle)
      self.notifyDesigner("eye2Rotation",self.eye2Rotation)
      self.notifyDesigner("eye2RotationAngle", rotatedAngle)
      self.notifyDesigner("eye3Rotation",self.eye3Rotation)
      self.notifyDesigner("eye3RotationAngle", rotatedAngle)
    },
    handleAllEyeBallsRotation(e) {
      const eyeBallsRotatedAngle = +e.target.value
      self.allEyeBallsRotation = eyeBallsRotatedAngle
      // self.ball1Rotation = [eyeBallsRotatedAngle]
      // self.ball2Rotation = [- eyeBallsRotatedAngle - 90]
      // self.ball3Rotation = [90 - eyeBallsRotatedAngle]
      // self.notifyDesigner("allEyeBallsRotation",eyeBallsRotatedAngle)
      // self.notifyDesigner("ball1Rotation",self.ball1Rotation)
      // self.notifyDesigner("ball1RotationAngle",eyeBallsRotatedAngle)
      // self.notifyDesigner("ball2Rotation",self.ball2Rotation)
      // self.notifyDesigner("ball2RotationAngle",eyeBallsRotatedAngle)
      // self.notifyDesigner("ball3Rotation",self.ball3Rotation)
      // self.notifyDesigner("ball3RotationAngle",eyeBallsRotatedAngle)
      self.setAllEyeBallRotation(eyeBallsRotatedAngle)
    },
    setAllEyeBallRotation(eyeBallsRotatedAngle) {
      let ballRotations = calculateAllEyeBallRotationForEachEyeBall(eyeBallsRotatedAngle)
      let ball1Rotation = ballRotations[0]
      let ball2Rotation = ballRotations[1]
      let ball3Rotation = ballRotations[2]
      self.ball1Rotation = [ball1Rotation]
      self.ball2Rotation = [ball2Rotation]
      self.ball3Rotation = [ball3Rotation]
      self.notifyDesigner("allEyeBallsRotation",eyeBallsRotatedAngle)
      self.notifyDesigner("ball1Rotation",self.ball1Rotation)
      self.notifyDesigner("ball1RotationAngle",eyeBallsRotatedAngle)
      self.notifyDesigner("ball2Rotation",self.ball2Rotation)
      self.notifyDesigner("ball2RotationAngle",eyeBallsRotatedAngle)
      self.notifyDesigner("ball3Rotation",self.ball3Rotation)
      self.notifyDesigner("ball3RotationAngle",eyeBallsRotatedAngle)
    },
    toogleOpenAllEyesRotation() {
      self.openAllEyesRotation = !self.openAllEyesRotation
    },
    toogleOpenIndividualEyesRotation(eyePosition) {
      if(eyePosition == 1) {
        self.openEyes1Rotation = !self.openEyes1Rotation
      } else if(eyePosition == 2) {
        self.openEyes2Rotation = !self.openEyes2Rotation
      } else if(eyePosition == 3) {
        self.openEyes3Rotation = !self.openEyes3Rotation
      }
    },
    handleIndividualEyeLidRotation(e, eyePosition) {
      const rotatedAngle = +e.target.value;

      const rotationMappings = {
        1: {
          90: ["fv"],
          180: ["fh", "fv"],
          270: ["fh"],
          default: [],
        },
        2: {
          90: ["fh", "fv"],
          180: ["fv"],
          270: [],
          default: ["fh"],
        },
        3: {
          90: [],
          180: ["fh"],
          270: ["fh", "fv"],
          default: ["fv"],
        },
      };

      const eyeRotation = rotationMappings[eyePosition]?.[rotatedAngle] || rotationMappings[eyePosition]?.default;

      switch (eyePosition) {
        case 1:
          self.eye1Rotation = eyeRotation;
          self.eye1RotationAngle = rotatedAngle;
          self.notifyDesigner("eye1Rotation", self.eye1Rotation);
          self.notifyDesigner("eye1RotationAngle", rotatedAngle);
          break;
        case 2:
          self.eye2Rotation = eyeRotation;
          self.eye2RotationAngle = rotatedAngle;
          self.notifyDesigner("eye2Rotation", self.eye2Rotation);
          self.notifyDesigner("eye2RotationAngle", rotatedAngle);
          break;
        case 3:
          self.eye3Rotation = eyeRotation;
          self.eye3RotationAngle = rotatedAngle;
          self.notifyDesigner("eye3Rotation", self.eye3Rotation);
          self.notifyDesigner("eye3RotationAngle", rotatedAngle);
          break;
        default:
          break;
      }
    },
    handleIndividualEyeBallRotation(e, eyePosition) {
      const rotatedAngle = +e.target.value;
      if(eyePosition == 1) {
        let ball1Rotation = rotatedAngle - 180
        self.ball1Rotation = [ball1Rotation]
        self.ball1RotationAngle = rotatedAngle
        self.notifyDesigner("ball1Rotation",self.ball1Rotation)
        self.notifyDesigner("ball1RotationAngle", rotatedAngle)
      } else if(eyePosition == 2) {
        let ball2Rotation = rotatedAngle - 180
        self.ball2Rotation = [ball2Rotation]
        self.ball2RotationAngle = rotatedAngle
        self.notifyDesigner("ball2Rotation",self.ball2Rotation)
        self.notifyDesigner("ball2RotationAngle", rotatedAngle)
      } else if(eyePosition == 3) {
        let ball3Rotation = rotatedAngle - 180
        self.ball3Rotation = [ball3Rotation]
        self.ball3RotationAngle = rotatedAngle
        self.notifyDesigner("ball3Rotation",self.ball3Rotation)
        self.notifyDesigner("ball3RotationAngle", rotatedAngle)
      }
    }
  }))


export default EyesAndModulesState;
