// noinspection DuplicatedCode

import React, {useContext, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  DropdownItem,
  Row,
  Button
} from 'reactstrap';

import * as Icon from "react-feather";
import { observer } from "mobx-react-lite";
import {types} from 'mobx-state-tree';
import { RouterLink } from "mobx-state-router";
import { FormattedMessage } from "react-intl";

import BatchListView from "../../../components/codes/batches/BatchListView";
import { StateContext } from "../../../App";
import { TableColumn } from "../../../components/tables/ScanbuyTable";
import Header from "../../../components/Header";
import TableActionsDropdown from "../../../components/tables/TableActionsDropdown";
import ScanbuyModal, {ModalState} from '../../../components/elements/modal/GenericModal';
import BatchItemListView from '../../../components/codes/batches/items/BatchItemListView';
import {BatchItemList} from '../../../models/batch/BatchItemList';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import BatchItemListState from '../../../components/codes/batches/items/BatchItemListState';
import BatchForm from '../../../components/codes/batches/BatchForm';
import { batchTypesConstatns, batchEditTypes, batchEditTag} from '../../../utils/constants';
import { hasNoSpaces } from '../../../utils/common';
import Notification from "../../../utils/Notification";
import { createActionColumn } from '../../../components/tables/TableColumns';

export const buildColumns = (routerStore, batchList) => {
  const {rootStore} = routerStore.options;
  const c1 = TableColumn.create({ name: 'uuid', property: 'uuid' });
  c1.setSelector((row) => row.uuid);

  const c2 = TableColumn.create({
    name: 'Status',
    property: 'status',
    sortable: true,
    sortField: 'status',
    selector: 'status',
    width: '15%'
  })
  c2.setSelector((row)=>{
    let status = row.status;
    // if status is DONE or DONE_WITH_ERRORS, check update time, if it's more than s3 presignUrl TTL time, show it expired
    status = checkStatus(status, row.updatedAt, row.batchType);
    if (status === 'DONE_WITH_ERRORS') {
        return (<><div><span>DONE</span><FontAwesomeIcon style={{color: 'grey'}} icon={faExclamationTriangle}/></div></>)
    } else {
        return (<div>{status}</div>)
    }
  })

  const c3 = TableColumn.create({ name: 'Batch Type', property: 'batchType', sortable: true, sortField: 'batchType', width: '15%'});
  c3.setSelector((row) => {
    return row.batchType;
  });

  const c4 = TableColumn.create({
    name: 'Batch Name',
    property: 'fileName',
    width: '20%'
  });
  c4.setSelector((row) => {
    return row.fileName;
  });

  const c5 = TableColumn.create({
    name: 'Total Items',
    property: 'totalItems',
    width: '10%',
    hide: 'sm'
  });
  c5.setSelector((row) => {
    return row.totalItems;
  });

  const c6 = TableColumn.create({
    name: 'Created',
    property: 'created',
    width: '15%',
    hide: 'sm',
    sortable: true,
    sortField: 'createdAt',
  });

  c6.setSelector((row) => {
    const date = new Date(row.createdAt);
    return date.toLocaleString();
  });

  const c7 = TableColumn.create({
    name: 'Updated',
    property: 'updated',
    width: '15%',
    hide: 'sm',
    sortable: true,
    sortField: 'updatedAt',
  });

  c7.setSelector((row) => {
    const date = new Date(row.updatedAt);
    return date.toLocaleString();
  });

  const actionColl = createActionColumn();

  actionColl.setCell((row) => {
    let status = checkStatus(row.status, row.updatedAt, row.batchType);
    return (
      <TableActionsDropdown actionsList={[
        <DropdownItem key={'batches/' + row.uuid + '/items'} onClick={() => {
            routerStore.goTo('batchItems', {
              params: { batchUuid: row.uuid},
            });
          }}>
          <Icon.Archive/>&nbsp;Details
        </DropdownItem>,

        status === 'DONE' && row.batchType === 'DOWNLOAD' ?
        (
          <DropdownItem key={'download/' + row.uuid} onClick={() => {
            const downloadFile = async () => {
              // Replace 'url-to-file' with the actual URL of the file to download
              if (row.details.presignUrl && row.details.presignUrl !== "") {
                saveAs(row.details.presignUrl);
              }
            }
            downloadFile();
          }}>
            <Icon.Download/>&nbsp;Download
          </DropdownItem>
        ) : null,

        status === 'MERGEABLE' ?
        (
          <DropdownItem key={'merge/' + row.uuid} onClick={async () => {
            const mergeBatch = async () => {
              await batchList.mergeBatch(row.uuid)
            }
            await mergeBatch();
            await batchList.fetchAll()
          }}>
            <Icon.Edit/>&nbsp;Merge
          </DropdownItem>
        ) : null,

        status === 'EXPIRED' ?
        (
          <DropdownItem key={'activate/' + row.uuid} onClick={async () => {
            const activateBatch = async () => {
              await batchList.activateBatch(row.uuid)
              status = "DONE"
            }
            await activateBatch();
            await batchList.fetchAll()
          }}>
            <Icon.RefreshCcw/>&nbsp;Reprocess
          </DropdownItem>
        ) : null,

        <DropdownItem key={'batches/' + row.uuid} onClick={async() => {
            const deleteBatch = async() => {await batchList.deleteBatch(row.uuid)}
            await deleteBatch();
            await batchList.fetchAll()
          }}>
            <Icon.Delete/>&nbsp;Delete
        </DropdownItem>
      ]}/>
   );
});

  return [
    // c1,
    c4,
    c3,
    c2,
    c5,
    c6,
    c7,
    actionColl
  ];

}

const checkStatus = (status, updatedAt, batchType) => {
  const updateTime = new Date(updatedAt);
  if ((status === 'DONE' || status === 'DONE_WITH_ERRORS') && batchType === 'DOWNLOAD') {
    if (Date.now() - updateTime.getTime() > 7200000) {
      return 'EXPIRED';
    }
  }
  return status;
}

const BatchListPage = observer(() => {
  console.log("Start Page, Batches")
  const stateContext = useContext(StateContext);
  const batchListViewState = stateContext.rootUiStore.batchListViewState
  const history = useHistory();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [tagDownload, setTagDownload] = useState('');
  // const batchManager = new BulkManager(new HttpClient(config.API_DOMAIN_NAME));

  /* Batch Item Modal*/
  const modalTitle = 'Batch Edit';
  const startOpen = false;
  const modalStore = ModalState.create({ open: startOpen, title: modalTitle });
  const batchItemStore = BatchItemList.create();

  const handleViewActionClick = async () => {
    // await batchItemStore.updateBatchUuid(rowUuid);
    modalStore.toggle();
  };

  const ComponentStoreState = types
  .model({})
  .actions((self) => ({
    saveAndClose(modalStore) {
      //do save.
      //if ok..
      return modalStore.toggle();
    },
  }));

  const componentStore = ComponentStoreState.create({});

  const batchItemColumn1 = TableColumn.create({ name: 'uuid', property: 'uuid' });
  batchItemColumn1.setSelector((row) => row.uuid);

  const batchItemColumn2 = TableColumn.create({ name: 'batchUuid', property: 'batchUuid' });
  batchItemColumn2.setSelector((row) => row.batchUuid);

  const batchItemColumn3 = TableColumn.create({
    name: 'Status',
    property: 'status',
    sortable: true,
    sortField: 'status',
    selector: 'status',
  })
  batchItemColumn3.setSelector((row)=>{
    return row.status
  })

  const batchItemColumns = [
    batchItemColumn1,
    batchItemColumn2,
    batchItemColumn3
  ];

  const batchItemsListState = BatchItemListState.create({
    store: batchItemStore,
    columns: batchItemColumns,
  });

  let enableEditSubmit = false;
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    enableEditSubmit = true;
    if (enableEditSubmit) {
      handleSubmit(e);
    }
  };

  const EditWarnInfo = () => {
    return (
      <>
        <div>Are you sure you want to update codes?</div>
        <div className="d-flex justify-content-between mt-3">
            <Button color="danger" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleEditSubmit} className="mr-2">
              Confirm
            </Button>
          </div>
        </>
    );
  };
  /* Batch Item Modal */

  // const actionCol = TableColumn.create({
  //   name: 'Actions',
  //   property: 'action',
  //   allowOverflow: true,
  //   button: true,
  //   width: '100px',
  // })
  // actionCol.setCell((row) => (
  //   <TableActionsDropdown actionsList={[
  //     <DropdownItem key={'view/'+row.uuid} onClick={() => handleViewActionClick(row.uuid)}><Icon.Search />View</DropdownItem>,
  //     <DropdownItem key={'edit/'+row.uuid}><Icon.Edit /><Link to={"/codes/batches/" + row.uuid}>Edit</Link></DropdownItem>,
  //     <DropdownItem key="divider" divider />,
  //     <DropdownItem key={'delete/'+row.uuid} className="align-text-bottom"><Icon.Delete /><Link to={"/codes/batches/delete/" + row.uuid}>Delete</Link></DropdownItem>
  //   ]} />
  // ))

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!batchFormState.isBatchEditable) {
      new Notification()
        .setType("error")
        .setMessage("Batch is not editable")
        .send();
      return
    }
    if (batchFormState.batchName === "") {
      new Notification()
        .setType("error")
        .setMessage("Batch Name is required")
        .send();
      return;
    }
    if (!hasNoSpaces(batchFormState.batchName)) {
      new Notification()
        .setType("error")
        .setMessage("Batch Name cannot contain spaces")
        .send();
      return;
    }
    if (batchFormState.batchType === batchTypesConstatns.EDIT && !enableEditSubmit) {
      handleViewActionClick();
      return;
    }
    let dimensions = {};
    batchFormState.dimensionSelectViewState.dimensionSelectStates.forEach(item => {
      dimensions[item.selectStateUuid] = item.selectedDimension.uuid;
    });
    if (batchFormState.batchType === batchTypesConstatns.DOWNLOAD || batchFormState.batchType === batchTypesConstatns.EDIT){
      if (!batchFormState.isDefaultBatchOperation && batchFormState.downloadCounts === 0) {
        new Notification()
          .setType("error")
          .setMessage("No code")
          .send();
        return;
      }
      let details = {};
      if(batchFormState.isDefaultBatchOperation){
        // let defaultOperation = batchFormState.batchSelectStore.selectedBatch.uuid;
        // details.defaultOperation = defaultOperation;
        details.defaultOperation = {batchUuid: batchFormState.batchSelectStore.selectedBatch.uuid};
      } else {
        console.log('isFilterTagTypeOR', batchFormState.isFilterTagTypeOR)
        details = {
          tags: batchFormState.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
          owner: {
            dimensions: dimensions,
            groupUuid: batchFormState.groupSelectStore.selectedGroup.uuid
          },
          filter: batchFormState.codeModels.searchParams,
          isFilterTagTypeOR: batchFormState.isFilterTagTypeOR,
        }
      }
      // details.defaultOperation = {batchUuid: batchFormState.batchSelectStore.selectedBatch};
      if(batchFormState.batchType === batchTypesConstatns.DOWNLOAD){
        let qrConfig = "";

        if (batchFormState.qrConfigSelectStore.selectedConfig != undefined) {
          qrConfig = batchFormState.qrConfigSelectStore.selectedConfig.uuid;
        }

        details.imageFormat = batchFormState.qrDownloadStore.downloadImageFormat.value;
        details.qrConfig = qrConfig;

      } else if (batchFormState.batchType === batchTypesConstatns.EDIT){
        let editType, editDetails;
        if(batchFormState.editType === batchEditTypes.URL){
          editType = "URL";
          editDetails = {"landingUrl": batchFormState.editValue};
          // details = {...details, ...batchFormState.editDetails}
        } else if(batchFormState.editType === batchEditTypes.STATUS){
          editType = "STATUS";
          editDetails = {"status": batchFormState.editValue};
        } else if(batchFormState.editType === batchEditTypes.TAG){
          editType = "TAG";
          if(batchFormState.tagOperation === batchEditTag.ADD_TAG){
            let allTags = [];
            // allTags.push(...batchFormState.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}));
            allTags.push(...batchFormState.updatedSelectedTags);
            editDetails = {"tags": allTags};
          } else if(batchFormState.tagOperation === batchEditTag.REPLACE_TAG || batchFormState.tagOperation === batchEditTag.REMOVE_TAG){
            editDetails = {"tags": batchFormState.updatedSelectedTags};
          }
          editDetails = {...editDetails, "tagOperation": batchFormState.tagOperation};
        }
        details = {...details, "editType": editType, "editDetails": editDetails};
        console.log("details: ", details)
      }
      await batchFormState.handleBulkDownloadOrEdit({
        batchName: batchFormState.batchName,
        details: details
      }, batchFormState.batchType);
    } else {
      const validateCsv = await batchFormState.checkCsvFile(uploadFiles, batchFormState.selectedCodeType);
      if(!validateCsv) {
        new Notification()
        .setType("error")
        .setMessage("Invalid CSV file.")
        .send();
        return
      }
      const details = {
        codeType: batchFormState.selectedCodeType,
        codeStatus: batchFormState.codeStatus,
        owner: {
          dimensions: dimensions,
          groupUuid: batchFormState.groupSelectStore.selectedGroup.uuid
        },
        tags: batchFormState.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        shortUrl: {
          domain: batchFormState.publishDomainSelectStore.selectedPublishDomain?.domain
        }
      }
      await batchFormState.handleCsvUpload({
        batchType: batchFormState.batchType,
        uploadFiles: uploadFiles,
        batchName: batchFormState.batchName,
        details: details
      });
    }
    batchListViewState.refresh()
    history.push("/batches");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    batchFormState.reset()
    history.push("/batches");
  };

  // const batchFormState = BatchFormState.create({title: "Create Batch"})

  const batchFormState = batchListViewState.batchFormState
  batchFormState.setHandleSubmit(handleSubmit)
  batchFormState.setUploadFiles(setUploadFiles)
  batchFormState.setHandleCancel(handleCancel)
  ///handleSubmit={handleSubmit} setUploadFiles={setUploadFiles}
  return (
    <Container fluid>
      <Header>
        <Breadcrumb>
          <BreadcrumbItem>
            <RouterLink routeName="dashboard" ><FormattedMessage id="routes.home.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <RouterLink routeName="codes" ><FormattedMessage id="routes.codes.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="routes.batches.defaultMessage"/></BreadcrumbItem>
        </Breadcrumb>
        <ScanbuyModal
          modalStore={modalStore}
          EmbedComponent={EditWarnInfo}
          />
      </Header>
      <Row>
        <Col sm={8}>
          <BatchListView batchListState={batchListViewState} />
        </Col>
        <Col sm={4}>
          <BatchForm batchFromState={batchFormState}  />
        </Col>
      </Row>
    </Container>
  )}
)
export default BatchListPage;
