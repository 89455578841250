import { types } from "mobx-state-tree";
import { BatchDetails } from "./BatchDetails";

export const Batch = types.model("Batch", {
  uuid: types.identifier,
  batchType: types.string,
  fileName: types.string,
  status: types.maybe(types.string, ""),
  totalItems: types.maybe(types.integer, 0),
  // remainingCodes: types.integer,
  createdAt: types.maybe(types.string, ""),
  updatedAt: types.maybe(types.string, ""),
  details: types.maybe(BatchDetails, {}),
});