import React from 'react'
import DataTable from 'react-data-table-component'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react-lite'
import {types} from "mobx-state-tree";
import {Progress, Spinner} from "reactstrap";


export const TableColumn = types.model('TableColumn', {
  name: types.string,
  property: types.string,
  sortable: types.optional(types.boolean, false),
  sortField: types.optional(types.string, ''),
  allowOverflow: types.optional(types.boolean, false),
  button: types.optional(types.boolean, false),
  width: types.maybeNull(types.string),
  hide: types.optional(types.string, ''),
  ignoreRowClick: types.optional(types.boolean, false)
}).volatile(self => ({
  selector: types.function,
  cell: types.function
}))
  .actions(self => ({
    setSelector(f) {
      self.selector = f
    },
    setCell(c) {
      self.cell = c
    }
  }))

//TODO: convert the customStyle to Model too


const ProgressSpinner = () => {
  return (
    <div>
      <Spinner color="dark" className="mr-2"/>
    </div>
  );
};

/**
 Datatable wraper component with remote pagination
 */
const ScanbuyTable = observer((props) => {
  const {
    title,
    columns,
    customStyles,
    store,
    isElasticSearchEnabled,
    queryParams = {},
    onRowClicked = undefined,
    pointerOnHover=false,
    highlightOnHover=false
  } = props

  const handlePerRowsChange = async (newPerPage, page) => {
    await store.updatePerPage(newPerPage)
    await store.updatePage(page)
    isElasticSearchEnabled ? await store.searchResults(queryParams) : await store.fetchAll(queryParams)

  }

  const handlePageUpdate = async (page) => {
    await store.updatePage(page)
    if(isElasticSearchEnabled) {
      await store.searchResults({sort: store.sortField, order:store.sortOrder, ...queryParams})
    } else {
      await store.fetchAll({sort: store.sortField, order:store.sortOrder, ...queryParams})
    }
  }

  const handleSort = async (column, sortDirection) => {
    await store.setSortOrder(sortDirection)
    await store.setSortField(column.sortField)
    if(isElasticSearchEnabled) {
      await store.searchResults({sort: column.sortField, order:sortDirection, ...queryParams})
    } else {
      await store.fetchAll({sort: column.sortField, order: sortDirection, ...queryParams})
    }
  }

  return (
    <>
      <DataTable
        title={title}
        columns={columns}
        data={store?.getItems() || []}
        customStyles={customStyles}
        striped
        highlightOnHover={highlightOnHover}
        pointerOnHover={pointerOnHover}
        progressPending={store?.isLoading}
        pagination
        paginationServer
        paginationRowsPerPageOptions={[10,25,50,100]}
        paginationTotalRows={store?.totalRows}
        paginationResetDefaultPage={false} // optionally, a hook to reset pagination to page 1
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageUpdate}
        onSort={handleSort}
        onRowClicked ={onRowClicked}
        sortServer
      />
    </>
  )
})

ScanbuyTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  customStyles: PropTypes.object,
  store: PropTypes.object,
}

export default ScanbuyTable
