import { types } from "mobx-state-tree";
import { TableColumn } from "../../tables/ScanbuyTable";
import { GroupSelectState } from "../../elements/groups/GroupSelectState";
import { BatchList } from "../../../models/batch/BatchList";
import BatchFormState from "../../codes/batches/BatchFormState";
import { BatchFilterSelectState } from "../../elements/filters/BatchFilterSelectState";
import lodash from "lodash";
import Group from "../../../models/Group";
import {ModalState} from "../../elements/modal/GenericModal";

const BatchListState = types.model('BatchListState',{
    store: types.maybe(types.reference(BatchList)), // <-- hold the store
    columns: types.array(types.late(() => TableColumn)),
    groupSelectStore: types.maybe(types.reference(GroupSelectState)),
    batchFormState: types.maybe(types.late(() => BatchFormState)),
    selectedGroup: types.maybe(types.reference(Group)),
    batchFilterSelectState: types.maybeNull(types.reference(BatchFilterSelectState)),
    modalStore: types.maybe(types.reference(ModalState)),
}).volatile((self) => ({
    modalComponent: types.object,
    modalComponentStore: types.object,
    modalSize: types.str,
    modalClassName: types.str,
}))
  .actions((self) => ({
    refresh(){
      self.store.fetchAll()
    },
    setOnSelectChanges() {
      self.groupSelectStore.setConsumer(self.selectGroup)
      self.batchFilterSelectState?.setConsumer(self.selectFilter)
    },
    selectFilter(obj, covered){
      self.store.removeSearchParams(covered);
      self.batchFormState.removeSearchParams(covered)
      if(obj){
        const params = lodash.mapValues(obj,(value, key) => {
          return {}[key] = value.join(',');
        });
        self.batchFormState.addSearchParams({groupUuid: obj?.uuid})
        self.store.addSearchParams(params)
        self.refresh()
      }
    },
    addRow(data) {
      //? P / ...
    },
    deleteRow(row) {

    },
    afterCreate() {

    },
    selectGroup(obj) {
      self.selectedGroup = obj;
      self.store.addSearchParams({groupUuid: obj?.uuid})
      self.batchFormState.addSearchParams({groupUuid: obj?.uuid})
      // self.refresh()
    },
    handleFilterChange(e) {
      console.log("BatchListState handleFilterChange")
      // self.store.fetchAll(e);
    }
  }))


export default BatchListState
